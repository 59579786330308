<script>
import { defineAsyncComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { CHECK_TYPE_INPUTS, SIGNER_COLORS } from '@/config/agreement-editor';
import HrbrAgreementEditorUploader from './HrbrAgreementEditorUploader.vue';
import { useAgreementEditorStore } from './stores/agreement-editor-store';
import { useSignersStore } from './stores/signers-store';
import { COMMON_DEFAULT_INPUTS, DEFAULT_INPUTS_MAP } from '@/config/verification-inputs';
import { range } from '@/utils/helpers/functions';
import { useHarbourStore } from '@/stores/harbour-store.js';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import handleDynamicError from '@/utils/dynamic-errors';
import { useCkeditorStore } from '../Ckeditor/stores/ckeditor-store';
import { useBrandingsStore } from '@/stores/brandings-store';
import { getBlobFromUrl, readFileAsDataUrl, delayWithAbort } from '@/utils/helpers/functions';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { publishEvent } from '@/utils/bus';
import { DraftTypes, defineDraftRole } from '@/domain/drafts/draft';
import { AgreementEditorService } from '@/services/agreement-editor-services';
import { useSuperdocStore } from '@/stores/superdoc-store';

export default {
  name: 'HrbrAgreementEditor',

  components: {
    HrbrAgreementEditorUploader,
    HrbrAgreementEditorAddSigners: defineAsyncComponent(() =>
      import('./HrbrAgreementEditorAddSigners.vue').catch((err) => handleDynamicError(err)),
    ),
    HrbrAgreementEditorAddFields: defineAsyncComponent(() =>
      import('./HrbrAgreementEditorAddFields.vue').catch((err) => handleDynamicError(err)),
    ),
    HrbrAgreementEditorStoreProgress: defineAsyncComponent(() =>
      import('./HrbrAgreementEditorStoreProgress.vue').catch((err) => handleDynamicError(err)),
    ),
  },

  props: {
    currentStep: {
      type: Number,
      required: false,
    },
    currentStepId: {
      type: String,
      required: true,
    },
    creationMode: {
      type: String,
      default: 'agreement',
    },
    agreementId: {
      type: String,
      default: null,
    },
    linkDisplayId: {
      type: String,
      default: null,
    },
    linkSigners: {
      type: Array,
    },
    isCopy: {
      type: Boolean,
      deafult: false,
    },
    addonKey: {
      type: String,
      default: null,
    },
    lastbrand: {
      type: Boolean,
      default: false,
    },
    linkItem: {
      type: Object,
      required: false,
    },
    source: {
      type: String,
    },
    templateGroupId: {
      type: String,
      default: null,
    },
    isEditingApprovalFlowLink: {
      type: Boolean,
      default: false,
    },
    isCompletedApproval: {
      type: Boolean,
      default: false,
    },
    approvalEditData: {
      type: Object,
      default: null,
    },
    fileToUpload: {
      type: File,
    },

    // ckeditor props
    ckeditorFileId: {
      type: String,
      default: null,
    },
    ckeditorHtmlData: {
      type: String,
      default: null,
    },
    isSkipToCk: {
      type: Boolean,
      default: false,
    },
    isBlankCkDocument: {
      type: Boolean,
      default: false,
    },
    isCkDocumentFromFile: {
      type: Boolean,
      default: false,
    },
    documentTitle: {
      type: String,
      default: ''
    },
    ckHtmlAnchors: {
      type: Array,
      defaul: () => [],
    },

    // drafts
    sourceDraftId: {
      type: String,
      default: null,
    },

    // pdf drafts
    isPdfDocumentFromFile: {
      type: Boolean,
      default: false,
    },

    // edit agreement
    publishLock: {
      type: String,
      default: ''
    },
    superdocProp: {
      type: Object,
      default: null,
    }
  },

  setup() {
    const agreementEditorStore = useAgreementEditorStore();
    const signersStore = useSignersStore();
    const harbourStore = useHarbourStore();
    const templatesStore = useTemplatesStore();
    const ckeditorStore = useCkeditorStore();
    const draftsStore = useDraftsStore();
    const superdocStore = useSuperdocStore();
    const brandingsStore = useBrandingsStore();
    const { selfServiceSections, currentCreationMode } = storeToRefs(agreementEditorStore);
    const { draftTemplateChangedData, draftTemplateRemovedInputs } = storeToRefs(draftsStore);
    const { activeSuperdoc } = storeToRefs(superdocStore);

    return {
      agreementEditorStore,
      signersStore,
      harbourStore,
      templatesStore,
      ckeditorStore,
      draftsStore,
      superdocStore,
      brandingsStore,
      selfServiceSections,
      draftTemplateChangedData,
      draftTemplateRemovedInputs,
      activeSuperdoc,
    };
  },

  data() {
    return {
      contextDict: null,
      uploadedFile: null,
      agreementTitle: '',
      agreementSubheader: 'please review and complete',
      brandingLogoImgUrl: null,
      brandingBannerImgUrl: null,
      signersList: [],
      signersListInfo: [],
      signerColors: [...SIGNER_COLORS],
      isReusableLinkEnabled: false,
      isActiveAgreement: true,
      pdfFiles: [],
      pdfPages: [],
      pdfPageScaleFactor: 2,
      pdfAnnotations: [],
      selectedTemplateGroupId: null,

      summaryString: '',
      summaryArray: [],
      currentSectionId: null,
      isPdfEditingEnabled: true,
      isSignerOrderEnforced: false,
      activeFileVersionDisplayId: null,
      topParentFileVersionDisplayId: null,
      isInputSettingsOpened: true,

      // Load agreement
      agreementData: null,
      activeTemplateId: null,
      activeLinkDisplayId: null,
      activeBrandingId: null,
      isUploaderStepFirstLoad: true,
      isAddFieldsStepFirstLoad: true,
      isAddSignersStepFirstLoad: true,
      isEditingAgreementMode: false,
      isCurrentlyStoringAgreement: false,
      publishStep: 0,

      // Check changing the signers
      isReusableLinkEnabledPreviousSelection: false,
      signersListPreviousSelection: [],
      isSignersSettingsChanged: false,

      // Remove default agreement editor fields
      isHiddenInputPdfPreview: false,
      isHiddenInputAcceptedAndAgreed: false,
      isHiddenInputFinalEsignature: false,
      isHiddenInputFinalDisplay: false,
      inputAcceptedAndAgreedLabel: null,
      inputFinalSubmitLabel: null,

      originalFileType: null,

      originalBase64: null,
      templateBase64: null,

      isReadyToRender: false,
      isFileRemove: false,

      // ckeditor data
      $_ckeditorInstance: null,
      documentSharing: null,
      draftId: null,
      currentHtml: null,
      skipToCk: false,
      syncComplete: false,
      firstCkOpen: true,
      isBlankDocument: false,
      isInitialCkeditorUpload: false,
      alreadyStoredCkeditorAgreement: false,
      ckeditorAnchors: [
        {
          name: null,
          id: null,
        },
      ],

      // pdf drafts
      isPdfPagesLoaded: false,
      agreeUpdateTimeout: 6 * 1000,
      agreeUpdateTimeoutId: null,
      agreeUpdatePollingController: null,
      alreadyStoredPdfAgreement: false,
      isSavingAgreementChanges: false,
      isAgreeUpdatePollingDestroyed: false,

      storeCkDataContoller: new AbortController(),
      isDestroyed: false,
    };
  },

  inject: ['nextStep', 'prevStep'],

  provide() {
    return {
      generateSignerId: this.generateSignerId,
      generateSignerColor: this.generateSignerColor,
    };
  },

  computed: {
    isAgreementCreationMode() {
      return this.creationMode === 'agreement';
    },

    isTemplateCreationMode() {
      return this.creationMode === 'template';
    },

    isPdfAgreement() {
      return this.originalFileType === 'pdf';
    },

    isSuperdocWordAgreement() {
      return !!(this.originalFileType === 'superdoc-word' && this.activeSuperdoc);
    },

    /**
     * @ckeditor computed
     */
    isCkeditorAgreement() {
      return this.originalFileType === 'word';
    },
  },

  methods: {
    updateCreationMode(creationMode) {
      this.$emit('update-creation-mode', creationMode);
    },

    updateCurrentStep(step) {
      this.$emit('current-step-change', step);
    },

    updateUploadedFile(file) {
      this.uploadedFile = file;
      this.originalBase64 = file?.fileBase64;
    },

    onTemplateSignersLoad(eventData) {
      // opened from dashboard, we need to load signers first
      if (this.creationMode === 'template') return;

      const { loadedSignersLength, isReusableLink } = eventData;

      console.log('onTemplateSignersLoad - eventData', eventData);

      // if user added signers, go to add fields
      if (loadedSignersLength > 0 || isReusableLink) {
        console.log('onTemplateSignersLoad - signers added, going to add fields');
        this.updateCurrentStep(2);
      } else {
        // prompt user to add signers
        console.log('onTemplateSignersLoad - no signers added, going to add signers');
        this.updateCurrentStep(1);
      }
    },

    updateSignersList(signersList) {
      this.signersList = signersList;
      if (this.signersList.length > 1) this.isReusableLinkEnabled = false;
    },

    updateSignersListInfo(signersListInfo) {
      this.signersListInfo = signersListInfo;
    },

    updateReusableLinkToggle(isEnabled) {
      this.isReusableLinkEnabled = isEnabled;
    },

    updateSignerOrderEnforced(isEnforced) {
      this.isSignerOrderEnforced = isEnforced;
    },

    updateTemplateGroupId(templateGroupId) {
      this.selectedTemplateGroupId = templateGroupId;
      this.updateSuperdocTemplate();
    },

    updateAgreementActiveState(isActive) {
      this.isActiveAgreement = isActive;
    },

    updateSignerInputs({ signerIdx, signerInputs }) {
      const signerInputsProp = `signerInputs${signerIdx + 1}`;
      this.signersStore[signerInputsProp] = signerInputs;
    },

    updateDocumentInputs(documentInputs) {
      this.signersStore.documentInputs = documentInputs;
    },

    updatePdfFiles(pdfFiles) {
      this.pdfFiles = pdfFiles;
    },

    updatePdfPages(pdfPages) {
      if (pdfPages.length) this.$emit('is-waiting-for-pdf-pages', false);
      this.pdfPages = pdfPages;
    },

    updatePdfAnnotations(pdfAnnotations, inputDependencyMap) {
      this.pdfAnnotations = pdfAnnotations;
      if (inputDependencyMap) {
        // add property for pdf preview - conditionally dependent
        this.pdfAnnotations.forEach((annotation) => {
          const currentAnnotation = annotation;
          if (inputDependencyMap.hasOwnProperty(currentAnnotation.inputitemid)) {
            currentAnnotation.isconditionallydependent = true;
          }
        });
      }
    },

    updateAnnotationById({ id, pdfAnnotation }) {
      const idx = this.pdfAnnotations.findIndex((a) => a.id === id);
      this.pdfAnnotations.splice(idx, 1, pdfAnnotation);
    },

    updateSummaryArray(summaryArray) {
      this.summaryArray = summaryArray;
    },

    updateCurrentSectionId(sectionId) {
      this.currentSectionId = sectionId;
    },

    updateAgreementSubheader(agreementSubheader) {
      this.agreementSubheader = agreementSubheader;
    },

    updateAgreementTitle(agreementTitle) {
      this.agreementTitle = agreementTitle;
      this.$emit('update-agreement-title', agreementTitle);

      const hasDraft = !!this.draftId;
      if (hasDraft) this.draftsStore.updateDraftNameByFileId(this.activeFileVersionDisplayId, agreementTitle);
    },

    updateBrandingBannerUrl(bannerUrl) {
      this.brandingBannerImgUrl = bannerUrl;
    },

    updateBrandingLogoUrl(logoUrl) {
      this.brandingLogoImgUrl = logoUrl;
    },

    updateFileVersionDisplayId(fileVersionDisplayId) {
      this.activeFileVersionDisplayId = fileVersionDisplayId;
    },

    updateTopParentFileVersionDisplayId(fileVersionDisplayId) {
      this.topParentFileVersionDisplayId = fileVersionDisplayId;
    },

    updateActiveTemplateId(activeTemplateId) {
      this.activeTemplateId = activeTemplateId;
      this.$emit('update-active-agreementid', activeTemplateId);
    },

    // Hide/show default agreement editor fields
    updateDefaultInputVisiablity(isHide, fieldName) {
      this[`isHidden${fieldName}`] = isHide;
    },

    updateDefaultInputLabel(label, fieldName) {
      this[`${fieldName}Label`] = label;
    },

    /**
     * @ckeidtor method
     */
    updateIsInputSettingsOpened(isOpened) {
      this.isInputSettingsOpened = isOpened;
    },

    /**
     * @ckeditor method
     */
    onInitialCkeditorUpload(isInitialUpload) {
      this.isInitialCkeditorUpload = isInitialUpload;
    },

    onFileUpload(params = {}) {
      const {
        file,
        originalFileType,
        isNextStep,
        isNextCkStep = false,
        isBlank,
        isCkDocFromFile,
      } = params;

      const isWordFile = originalFileType === 'word';
      const isPdfFile = originalFileType === 'pdf';

      if (isPdfFile) {
        this.$emit('update-waiting-ck-instance', false);
      }
      this.updateUploadedFile(file);
      this.initPdfFiles(file);
      this.setOriginalFileType(originalFileType);
      this.updateAgreementTitleFromFileName();

      if (isNextStep) {
        this.goNextStep();
      } else if (isNextCkStep) {
        const isCkTemplate = isWordFile && this.creationMode === 'template';
        const goNextCkStep = () => {
          this.isInitialCkeditorUpload = true;
          this.isReusableLinkEnabled = true;
          this.changeSignersListIfReusableLink();
          this.updateCurrentStep(2);
        };

        if (isCkTemplate) this.goNextStep();
        else goNextCkStep();
      } else if ((isBlank || isCkDocFromFile) && !this.isSuperdocWordAgreement) {
        this.isInitialCkeditorUpload = true;
        this.isReusableLinkEnabled = true;
        this.isBlankDocument = true;
        this.changeSignersListIfReusableLink();
        this.updateCurrentStep(2);
      } else if (isBlank && this.isSuperdocWordAgreement) {
        this.isReusableLinkEnabled = true;
        // this.isBlankDocument = true;
        this.changeSignersListIfReusableLink();
        this.updateCurrentStep(2);
      }
    },

    setOriginalFileType(fileType) {
      this.originalFileType = fileType || null;
    },

    updateAgreementTitleFromFileName() {
      if (!this.agreementTitle && this.uploadedFile) {
        this.agreementTitle = this.uploadedFile.fileDocumentName;
      }
    },

    onFileRemove() {
      this.updateUploadedFile(null);
      this.updatePdfFiles([]);
      this.updatePdfPages([]);
      this.updatePdfAnnotations([]);
    },

    onFileError() {
      this.updateUploadedFile(null);
      this.updatePdfFiles([]);
      this.updatePdfPages([]);
      this.updatePdfAnnotations([]);
    },

    /**
     * @ckeditor method
     */
    onSyncComplete() {
      this.syncComplete = true;
    },

    initPdfFiles(file) {
      if (!file) return;

      const pdfFile = {
        pdfid: file.fileId,
        pdffilename: file.fileName,
        pdfagreementname: file.fileDocumentName,
        pdfbytes: file.fileSizeBytes,
        pdfbase64: file.fileBase64,
        pdfinputmethod: file.fileInputMethod,
      };

      this.updatePdfPages([]);
      this.updatePdfFiles([pdfFile]);
    },

    /**
     * @ckeditor method
     */
    onCkeditorReady(editorInfo) {
      this.$emit('editor-ready', editorInfo);
      this.$_ckeditorInstance = editorInfo.editor;
      if (this.ckeditorFileId || this.alreadyStoredCkeditorAgreement) return;

      // if user immediately clicked next btn
      // then we don't need to call this method
      const signal = this.storeCkDataContoller?.signal;
      delayWithAbort(2500, signal)
        .then(() => {
          this.storeCkeditorDataAndActiveAgreement();
          this.alreadyStoredCkeditorAgreement = true;
        })
        .catch((err) => {
          if (err.name === 'AbortError') {
            console.log('Promise aborted');
          }
        });
    },

    onCkeditorDestroy() {
      this.$emit('editor-destroy');
    },

    onCkeditorError(error) {
      this.$emit('editor-error', error);
    },

    /**
     * @ckeditor method
     */
    async storeCkeditorDataAndActiveAgreement() {
      try {
        const hideStoringMessage = true;
        await this.storeCkeditorData(hideStoringMessage);

        const pdfPages = this.getFinalizedPdfPages();
        const defaultSignersJsonArray = this.getFinalizedSignersList();

        // apply section index to each input
        this.addSectionIndexToAllInputs();

        // store active agreement (for persistence of user inputs)
        const responseData = await this.storeAgreementRequest({
          defaultSignersJsonArray,
          pdfPages,
        });

        if (!responseData) {
          throw new Error('Missing stored agreement data');
        }

        const storedNewAgreementId = responseData.newagreementid;

        await this.ckeditorStore.saveCkeditorMetadata({
          fileDisplayId: this.activeFileVersionDisplayId,
          refids: {
            activeagreementid: storedNewAgreementId,
          },
        });

        const shouldCreateDraft = (
          this.isCkeditorAgreement &&
          !this.draftId &&
          !this.isEditingAgreementMode
        );

        if (shouldCreateDraft) {
          const isTemplate = this.creationMode === 'template';
          const createdDraft = await this.draftsStore.storeDraft({
            name: this.uploadedFile.fileDocumentName,
            ckFileId: this.activeFileVersionDisplayId,
            agreementId: storedNewAgreementId,
            role: defineDraftRole(isTemplate),
          });
          this.draftId = createdDraft.id;
          publishEvent('drafts:ae-draft-created', createdDraft);
        }
      } catch (err) {
        console.error(err);
        this.$buefy.toast.open({
          message:
            'Unable to store agreement at this time -- try again in a bit or contact support@harbourshare.com',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
      }
    },

    async onPdfPagesLoaded() {
      if (!this.isPdfAgreement) return;

      this.isPdfPagesLoaded = true;

      const shouldStorePdfDraftAgreement = (
        !this.agreementId &&
        !this.sourceDraftId &&
        !this.alreadyStoredPdfAgreement
      );

      if (shouldStorePdfDraftAgreement) {
        const agreementData = await this.storePdfDraftActiveAgreement();
        if (agreementData) {
          const { agreementId } = agreementData;
          this.initAgreementUpdatePolling(agreementId);
        }
      }
    },

    async storePdfDraftActiveAgreement() {
      this.alreadyStoredPdfAgreement = true;

      try {
        const pdfPages = this.getFinalizedPdfPages();
        const defaultSignersJsonArray = this.getFinalizedSignersList();

        // sections are initiated in the third step,
        // but they are needed to store the agreement
        this.setSectionsForActiveAgreement();

        // apply section index to each input
        this.addSectionIndexToAllInputs();

        // store active agreement (for persistence of user inputs)
        const responseData = await this.storeAgreementRequest({
          defaultSignersJsonArray,
          pdfPages,
        });

        if (!responseData) {
          throw new Error('Missing stored agreement data');
        }
        const storedNewAgreementId = responseData.newagreementid;

        const asTemplate = this.creationMode === 'template';
        const createdDraft = await this.draftsStore.storeDraft({
          name: this.uploadedFile.fileDocumentName,
          ckFileId: this.activeFileVersionDisplayId,
          agreementId: storedNewAgreementId,
          role: defineDraftRole(asTemplate),
          type: DraftTypes.Pdf,
        });
        this.draftId = createdDraft.id;
        publishEvent('drafts:ae-draft-created', createdDraft);

        return {
          agreementId: storedNewAgreementId,
        };
      } catch (err) {
        this.$buefy.toast.open({
          message: `Unable to store agreement at this time -- try again in a bit or contact support@harbourshare.com`,
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
        console.error(err);
        return null;
      }
    },

    setSectionsForActiveAgreement() {
      const sectionId = this.generateSectionId();
      const selfServiceSections = [
        {
          id: sectionId,
          title: 'Section 1',
          icon: 'pen',
          iconpack: 'fas',
          isOpen: true,
          inputs: []
        },
      ];
      this.updateCurrentSectionId(sectionId);
      this.selfServiceSections = selfServiceSections;
    },

    generateSectionId() {
      return `section-${this.selfServiceSections.length + 1}_${Math.trunc(Math.random() * 1e10)}-${
        Date.now() * 1e3
      }`;
    },

    getCurrentSuperdocBranding() {
      return {
        brandingId: this.activeBrandingId,
        brandingbannerbase64: this.brandingBannerImgUrl,
        brandinglogobase64: this.brandingLogoImgUrl,
      };
    },

    /**
     * Update the superdoc composable depending on what stage we are on
     */
    updateSuperdoc() {
      if (!this.activeSuperdoc) return;
      if (this.currentStepId === 'add-signers') {
        this.activeSuperdoc.agreement.signers = this.signersList;
        this.activeSuperdoc.agreement.isPublicLink = this.isReusableLinkEnabled;
        this.activeSuperdoc.agreement.isSetSigningOrder = this.isSignerOrderEnforced;
      }

      else if (this.currentStepId === 'add-fields') {
        this.activeSuperdoc.agreement.signers = this.getFinalizedSignersList();
        this.activeSuperdoc.agreement.setSignerInputs(this.signersStore.allSignerInputsList);
        this.activeSuperdoc.agreement.setAgreementInputs(this.signersStore.documentInputs);

        this.activeSuperdoc.agreement.subheader = this.agreementSubheader;
        this.activeSuperdoc.agreement.agreementTitle = this.agreementTitle;
        this.activeSuperdoc.agreement.setSections(this.selfServiceSections);

        // Branding and default banner/logo if any
        this.activeSuperdoc.agreement.branding = this.getCurrentSuperdocBranding();

        // Default inputs state (for any removed form items)
        this.activeSuperdoc.agreement.defaultInputsState = {
          ishiddeninputpdfpreview: this.isHiddenInputPdfPreview,
          ishiddeninputacceptedandagreed: this.isHiddenInputAcceptedAndAgreed,
          ishiddeninputfinalesignature: this.isHiddenInputFinalEsignature,
          ishiddeninputfinaldisplay: this.isHiddenInputFinalDisplay,
          inputacceptedandagreedlabel: this.inputAcceptedAndAgreedLabel,
          inputfinalsubmitlabel: this.inputFinalSubmitLabel,
        };
      };

      this.activeSuperdoc.agreement.title = this.agreementTitle;
    },

    superdocFinalStep() {
      this.updateSuperdoc();
      this.finalUpdateSuperdocAgreement();
      this.postStoringActions();
    },

    goNextStep() {
      try {
        this.validateBeforeGoNext();

        if (this.currentStepId === 'add-signers') {
          const isSettingsChanged = this.checkSignersSettingsChanged();
          this.isSignersSettingsChanged = this.isSignersSettingsChanged || isSettingsChanged;

          if (!this.isSignersSettingsChanged) {
            this.changeSignersListIfReusableLink();
            this.updateSuperdoc()
            this.nextStep();
            return;
          }

          const [shouldShowConfirm, message] = this.shouldShowSignersConfirm();
          const goNext = () => {
            this.updateLinkChangedSigners(); // if settings changed
            this.changeSignersListIfReusableLink();
            this.updateSuperdoc()
            this.nextStep();
          };

          if (shouldShowConfirm) {
            this.confirmChangingSignersSettings(message, () => goNext());
          } else {
            goNext();
          }
        } else if (this.currentStepId === 'add-fields') {
          if (!this.isLinkedInputsValid()) return;

          // If superdoc, handle next step separately
          if (this.activeSuperdoc) return this.superdocFinalStep();

          // No superdoc, proceed with the next step
          this.validateAddFieldsStepAndStartStoring();
        } else {
          this.changeSignersListIfReusableLink();
          if (this.currentStep === 0) this.updateCurrentStep(1);
          else this.nextStep();
        }
      } catch (err) {
        // validation error, no handling
      }
    },

    checkSignersSettingsChanged() {
      const isAddSignersStep = this.currentStepId === 'add-signers';

      if (isAddSignersStep && this.isAddSignersStepFirstLoad) return false;

      const isReusableEnabledPrev = this.isReusableLinkEnabledPreviousSelection;
      const isReusableLinkChanged = this.isReusableLinkEnabled !== isReusableEnabledPrev;
      if (isReusableLinkChanged) return true;

      const signersListPrev = this.agreementData?.defaultsigners || [];
      const isSignersLengthChanged = this.signersList.length !== signersListPrev.length;
      if (isSignersLengthChanged) return true;

      const isAnySignerChanged = this.checkAnySignerChanged();
      if (isAnySignerChanged) return true;
      return false;
    },

    checkAnySignerChanged() {
      const signersListPrev = this.signersListPreviousSelection;
      if (!signersListPrev.length) return false;
      let isAnySignerChanged = false;
      for (const signer of this.signersList) {
        const isSignerExists = this.checkSignerExists(signersListPrev, signer);
        if (!isSignerExists) {
          isAnySignerChanged = true;
          break;
        }
      }
      return isAnySignerChanged;
    },

    checkSignerExists(signersList, signer) {
      let { id, signername, signeremail } = signer;
      return signersList.some((s) => {
        const isIdEqual = s.id === id;
        const isEmailEqual = s.signeremail === signeremail;
        const isNameEqual = s.signername === signername;
        return isIdEqual && isEmailEqual && isNameEqual;
      });
    },

    shouldShowSignersConfirm() {
      if (!this.isSignersSettingsChanged) return [false, ''];

      const isCreatingAgreement = !this.activeTemplateId;
      const isAnySignerHasInputs = this.checkAnySignerHasInputs();

      // creating new agreement/template
      if (isCreatingAgreement) {
        const message = `This will remove all signer fields (except Agreement Owner fields).`;
        if (isAnySignerHasInputs) return [true, message];
        return [false, ''];
      }

      // editing active agreement/template
      const signersListPrev = this.signersListPreviousSelection;
      const isSignersRemoved = this.signersList.length < signersListPrev.length;
      if (isSignersRemoved) {
        const message = `This will remove fields for signers that were deleted.`;
        const message2 = `This will remove fields for signers that were deleted and for any newly added signers.`;
        const hasNewInputs = this.checkNewTemplateSignersHaveInputs(this.signersList);
        if (hasNewInputs) return [true, message2];
        return [true, message];
      }

      const isSignersAdded = this.signersList.length > signersListPrev.length;
      if (isSignersAdded) {
        const message = `This will remove fields for newly added signers.`;
        const hasNewInputs = this.checkNewTemplateSignersHaveInputs(this.signersList);
        if (hasNewInputs) return [true, message];
        return [false, ''];
      }

      const isAnySignerChanged = this.checkAnySignerChanged();
      const isSignersLengthEqual = this.signersList.length === signersListPrev.length;
      if (isAnySignerChanged && isSignersLengthEqual) {
        const message = `This will remove fields for newly added signers.`;
        const hasNewInputs = this.checkNewTemplateSignersHaveInputs(this.signersList);
        if (hasNewInputs) return [true, message];
        return [false, ''];
      }
      return [false, ''];
    },

    checkNewTemplateSignersHaveInputs(signersList = []) {
      const defSigners = this.agreementData?.defaultsigners || [];
      const newSigners = signersList.filter((s) => !this.checkSignerExists(defSigners, s));
      if (!newSigners.length) return false;

      const allInputs = [];
      for (const signer of newSigners) {
        const signerIdx = this.signersListInfo.findIndex((s) => (
          s.signeremail === signer.signeremail && s.signerid === signer.signerid
        ));
        if (signerIdx === -1) continue;
        const signerInputs = this.signersStore[`signerInputs${signerIdx + 1}`];
        allInputs.push(...signerInputs);
      }
      const allInputsFiltered = allInputs.filter(this.isNotDefaultInputPredicate);
      return allInputsFiltered.length > 0;
    },

    checkAnySignerHasInputs() {
      const { allSignerInputs = [] } = this.signersStore;
      const filtered = allSignerInputs.filter(this.isNotDefaultInputPredicate);
      return filtered.length > 0;
    },

    isNotDefaultInputPredicate(input) {
      return input.itemtype !== 'DISPLAYTEXT' && input.itemtype !== 'YESNO';
    },

    confirmChangingSignersSettings(message, callback) {
      const customMessage = `
        Are you sure you would like to update your signer settings?
        ${message}
      `;

      this.$buefy.dialog.confirm({
        title: 'Change signers settings',
        message: customMessage,
        onConfirm: () => {
          this.isSignersSettingsChanged = true;
          if (typeof callback === 'function') callback();
        },
        onCancel: () => {
          this.isSignersSettingsChanged = false;
        },
      });
    },

    updateLinkChangedSigners() {
      if (!this.activeLinkDisplayId) return;
      this.agreementEditorStore.addLinkChangedSigners(this.activeLinkDisplayId);
    },

    goPrevStep() {
      if (this.activeSuperdoc && this.currentStepId === 'add-signers') {
        this.activeSuperdoc.agreement.title = this.agreementTitle;
        this.activeSuperdoc.agreement.fields.agreementInputs = this.signersStore.documentInputs;
        this.superdocStore.openSuperdocFromDrafts({
          mode: this.creationMode,
          agreementId: this.agreementId,
          linkDisplayId: this.linkDisplayId,
          parentComponent: this,
          superdocProp: this.activeSuperdoc,
          templateGroupId: this.selectedTemplateGroupId,
        });
        this.closeModal();
        return;
      };

      try {
        if (this.isEditingAgreementMode && this.currentStepId === 'add-signers') {
          return;
        }

        if (this.activeSuperdoc) this.updateSuperdoc();
        this.validateBeforeGoPrevious();
        this.prevStep();
      } catch (error) {
        //
      }
    },

    validateBeforeGoPrevious() {
      const validationFunctions = [
        this.validateAddSignersStepOnPrevious,
        this.validateAddFieldStepOnPrevious,
      ];

      validationFunctions.forEach((validationFunc) => {
        validationFunc();
      });
    },

    validateBeforeGoNext() {
      const validationFunctions = [this.validateUploadDocumentStep, this.validateAddSignersStep];

      validationFunctions.forEach((validationFunc) => {
        validationFunc();
      });
    },

    validateUploadDocumentStep() {
      if (this.currentStepId !== 'upload-document') return;

      if (!this.uploadedFile) {
        this.$buefy.toast.open({
          message: 'File not uploaded yet',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });

        throw new Error('fileNotUploaded');
      }
    },

    validateAddSignersStep() {
      if (this.currentStepId !== 'add-signers') return;

      if (this.isAgreementCreationMode) {
        if (!this.isReusableLinkEnabled) this.validateSignersList();
        return;
      }
      if (this.isTemplateCreationMode) {
        this.validateTemplateGroupId();
      }
    },

    changeSignersListIfReusableLink() {
      if (this.currentStepId !== 'add-signers') return;

      if (this.isReusableLinkEnabled) {
        let [firstSigner] = this.signersList;
        firstSigner = {
          ...firstSigner,
          signername: null,
          signeremail: null,
          signerid: this.generateSignerId(),
          signercolor: this.generateSignerColor(),
        };
        const newSignersList = [firstSigner];
        this.updateSignersList(newSignersList);
      }
    },

    async validateAddFieldsStepAndStartStoring() {
      if (this.currentStepId !== 'add-fields') return;

      // store ckeditor pdf data to proceed with agreement editor
      if (this.isCkeditorAgreement) {
        this.storeCkDataContoller?.abort();
        await this.storeCkeditorData();
      }

      // if template or existing agreement, update
      this.activeTemplateId ? await this.updateAgreement() : await this.storeAgreement();
      this.postStoringActions();
    },

    postStoringActions() {
      // save user's selected options
      this.saveUserSelectedOptions();

      this.harbourStore.setLastCreatedAgreementLinkOptions();
      this.harbourStore.getUserLastSelectedOptions();
    },

    getDefaultLinkedInputs(checkType) {
      return COMMON_DEFAULT_INPUTS.concat(DEFAULT_INPUTS_MAP[checkType] || []);
    },

    // Filter fields only for selected provider
    getRequiredInputLinks(input) {
      const defaultLinks = this.getDefaultLinkedInputs(input.itemfieldtype);

      return Object.keys(input.autoaddedinputsmap).filter(key => {
        const inputConfig = defaultLinks.find(i => i.internalname === key);
        if (inputConfig.provider) return inputConfig.provider === input.itemtypeconfig?.provider;
        return key;
      }).map(key => input.autoaddedinputsmap[key]);
    },

    isLinkedInputsValid() {
      let inputToShow;
      const hasAllLinks = this.signersStore.allSignerInputs.every((input) => {
        const links = Object.values(input.autoaddedinputsmap);
        if (links.length) {
          const defaultLinks = this.getDefaultLinkedInputs(input.itemfieldtype);
          const requiredLinks = this.getRequiredInputLinks(input);

          if (!requiredLinks.every((id) => id !== null) || defaultLinks.length !== links.length) {
            inputToShow = input;
            return false;
          }
        } else if (!links.length && CHECK_TYPE_INPUTS.includes(input.itemfieldtype)) {
          return false;
        }
        return true;
      });

      let linkWithWrongSection;
      const isLinkSectionsValid = this.signersStore.allSignerInputs.every((input) => {
        const requiredLinks = this.getRequiredInputLinks(input);

        if (requiredLinks.length) {
          return requiredLinks.every((id) => {
            const linkedInput = this.signersStore.allSignerInputs.find((item) => item.id === id);
            if (!linkedInput) return false;
            const linkSectionIndex = this.selfServiceSections.findIndex(
              (section) => section.id === linkedInput.sectionid,
            );
            const checkInputSectionIndex = this.selfServiceSections.findIndex(
              (section) => section.id === input.sectionid,
            );
            if (
              linkedInput.sectionid !== input.sectionid &&
              linkSectionIndex > checkInputSectionIndex
            ) {
              linkWithWrongSection = linkedInput;
              return false;
            }
            return true;
          });
        }
        return true;
      });

      if (hasAllLinks && isLinkSectionsValid) {
        return true;
      } else if (linkWithWrongSection) {
        this.$buefy.toast.open({
          message: `${linkWithWrongSection.itemdisplaylabel} input should be placed in previous section`,
          type: 'is-danger',
          position: 'is-top',
          duration: 7000,
        });
      } else {
        this.$buefy.toast.open({
          message: `Please link all required inputs for verification fields`,
          type: 'is-danger',
          position: 'is-top',
          duration: 5000,
        });
        this.updateIsInputSettingsOpened(true);
        inputToShow.isitemlinkedinputsexpanded = true;
        this.agreementEditorStore.setSettingsActiveInput(inputToShow);
        return false;
      }
    },

    validateSignersList() {
      this.signersList.forEach((signer, signerIndex) => {
        const signerName = signer.signername;
        const signerEmail = signer.signeremail;

        if (!signerName) {
          this.$buefy.toast.open({
            message: `Please provide a valid name for signer (Row ${
              signerIndex + 1
            } has invalid name)`,
            type: 'is-danger',
            position: 'is-top',
            duration: 3500,
          });
          throw new Error('InvalidSignerName');
        }
        if (!signerEmail || !this.emailValidator(signerEmail)) {
          this.$buefy.toast.open({
            message: `Please provide a valid email for signer (Row ${
              signerIndex + 1
            } has invalid email)`,
            type: 'is-danger',
            position: 'is-top',
            duration: 3500,
          });
          throw new Error('InvalidSignerEmail');
        }
      });
    },

    validateTemplateGroupId() {
      if (!this.selectedTemplateGroupId) {
        this.$buefy.toast.open({
          message: 'Please select or create a template group to proceed',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
        throw new Error('templateGroupNotSelected');
      }
    },

    validateAddSignersStepOnPrevious() {
      if (this.currentStepId !== 'add-signers') return;
      this.isUploaderStepFirstLoad = false;
    },

    validateAddFieldStepOnPrevious() {
      if (this.currentStepId !== 'add-fields') return;
      this.isAddFieldsStepFirstLoad = false;
      this.isAddSignersStepFirstLoad = false;
      this.isReusableLinkEnabledPreviousSelection = this.isReusableLinkEnabled;
      this.signersListPreviousSelection = JSON.parse(JSON.stringify(this.signersList));
    },

    startNewDocument() {
      this.closeModal();
    },

    setInitialData() {
      this.contextDict = this.harbourStore.contextDict;

      // clear previous value in store
      this.selfServiceSections = [];
      if (this.agreementId) {
        this.activeTemplateId = this.agreementId;
        this.isEditingAgreementMode = true;
      }
      if (this.linkDisplayId) {
        this.activeLinkDisplayId = this.linkDisplayId;
      }
      if (this.ckeditorFileId) {
        this.alreadyStoredCkeditorAgreement = true;
        this.activeFileVersionDisplayId = this.ckeditorFileId;
        this.setOriginalFileType('word');
    }
      this.draftId = this.sourceDraftId || null;

      // Initialize the active superdoc from the prop data
      if (this.superdocProp) this.activeSuperdoc = this.superdocProp;

      // Initialize superdoc data
      if (this.activeSuperdoc) this.initializeSuperdocAgreement();
    },

    async initializeSuperdocAgreement() {
      const agreementTitle = this.activeSuperdoc.agreement?.title || this.activeSuperdoc.name;
      this.updateAgreementTitle(agreementTitle);

      this.setOriginalFileType('superdoc-word');
      this.updateFileVersionDisplayId(this.activeSuperdoc.id);

      // If we have a preloaded agreement, set the template Id if any
      const templates = ['template', 'template-draft'];
      this.is_template = templates.includes(this.creationMode) ? true : false;
      if (this.activeSuperdoc && this.is_template) {
        this.selectedTemplateGroupId = this.activeSuperdoc.agreement.templateGroupId;
      };

      // If we do not have an agreement, create a new one
      if (!this.activeSuperdoc.agreement?.id) {
        this.setSectionsForActiveAgreement();
        this.addSectionIndexToAllInputs();
        try {
          const responseData = await this.storeAgreementRequest({
            defaultSignersJsonArray: this.getFinalizedSignersList(),
            pdfPages: [],
          });

          const { newagreementid: agreementId } = responseData;

          if (!agreementId || !this.activeSuperdoc?.id) return;
          const agreementData = await AgreementEditorService.getAgreementDetails({
            agreementId,
            linkDisplayId: this.linkDisplayId,
            superdocId: this.activeSuperdoc?.id,
          });

          this.activeSuperdoc?.connectAgreement(agreementData);
        } catch (err) {
          console.error(err);
        };
      }

      this.isPdfPagesLoaded = true;
      this.activeTemplateId = this.activeSuperdoc?.agreement.id;
      this.initAgreementUpdatePolling(this.activeSuperdoc?.agreement.id);
    },

    updateSuperdocTemplate() {
      if (!this.activeSuperdoc?.id || !this.is_template) return;
      this.changeAgreementPublishSettings();
    },

    emailValidator(email) {
      if (!email) return true;
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    generateSignerColor() {
      if (this.signerColors.length) {
        const predefinedHexColor = this.signerColors.shift();
        return predefinedHexColor;
      }
      return this.generateRandomHexColor();
    },

    generateSignerId() {
      return `signerid-${Date.now()}-${Math.random().toString(36).slice(2)}`;
    },

    generateRandomHexColor() {
      return `#${Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, '0')}`;
    },

    resetSignerColors() {
      this.signerColors = [...SIGNER_COLORS];
    },

    attachStepHandlers() {
      this.$parent.$on('prev-step', this.goPrevStep);
      this.$parent.$on('next-step', this.goNextStep);
    },

    failedToLoadAgreementDetails() {
      this.$buefy.toast.open({
        message: 'Unable to load template details - continue to create template',
        type: 'is-danger',
        position: 'is-top',
        duration: 4500,
      });
      this.$emit('agreement-data-change', null);
    },

    async getAgreementDetails() {
      return await AgreementEditorService.getAgreementDetails({
        agreementId: this.agreementId,
        linkDisplayId: this.linkDisplayId,
        superdocId: this.activeSuperdoc?.id,
      });
    },

    initAgreementData(responseData) {
      this.agreementContentJson = responseData.agreementcontentjson;

      // load ckeditor anchors
      const customInputsJson = responseData.agreementcustominputjson;
      const ckeditorAnchors = customInputsJson?.ckeditoranchors || [{
        name: null,
        id: null,
      }];
      this.ckeditorAnchors = ckeditorAnchors;

      // anchors from ck html (source - ck modal)
      const ckHtmlAnchors = this.ckHtmlAnchors || [];
      ckHtmlAnchors.forEach((anchor) => {
        const isFound = !!this.ckeditorAnchors.find((i) => i.id === anchor.id);
        if (!isFound) this.ckeditorAnchors.push(anchor);
      });

      // Check to see if default 'yesno' has been hidden
      const sections = this.agreementContentJson?.sections;
      if (sections) {
        const autoInserted = sections[0].sectionitems.filter((item) =>
          item.itemid.includes('readagreedocumentyesno'),
        );
        if (!autoInserted?.length) {
          this.ishiddeninputacceptedandagreed = true;
          this.updateDefaultInputVisiablity(true, 'InputAcceptedAndAgreed');
        } else {
          this.ishiddeninputacceptedandagreed = false;
          this.updateDefaultInputVisiablity(false, 'InputAcceptedAndAgreed');
        }
      }

      if (this.agreementContentJson.sections.length) {
        this.currentSectionId = this.agreementContentJson.sections[0].sectionid;
        this.selfServiceSections = this.agreementContentJson.sections.map((section) => ({
          icon: section.sectionicon,
          iconpack: section.sectioniconpack,
          id: section.sectionid,
          title: section.sectiontitle,
          isOpen: true,
          inputs: []
        }));
      }
      this.agreementTitle = responseData.agreementtitle;
      this.agreementSubheader = responseData.agreementsubheader;
      this.selectedTemplateGroupId = responseData.template_group_id;
      this.is_template =  responseData.is_template;

      // Check for superdoc based template draft
      if (this.activeSuperdoc && this.activeSuperdoc.state === 'template-draft') this.is_template = true;

      const hasFinalReview = this.agreementContentJson.sections.some((section) =>
        section.sectionitems.some((item) => item.itemtype === 'FINALDISPLAYTEXT'),
      );
      this.updateDefaultInputVisiablity(!hasFinalReview, 'InputFinalDisplay');
      const hasEsignature = this.agreementContentJson.sections.some((section) =>
        section.sectionitems.some(
          (item) => item.itemtype === 'CHECKBOX' && item.itemgroup === 'esignconsent',
        ),
      );
      this.updateDefaultInputVisiablity(!hasEsignature, 'InputFinalEsignature');

      let finalSubmitItem = null;
      this.agreementContentJson.sections.forEach((section) => {
        finalSubmitItem = section.sectionitems.find(
          (item) =>
            item.isautoinserteddefaultitem &&
            item.iteminputtededitorselectiontype === 'SUBMITBUTTON',
        );
      });
      if (finalSubmitItem) {
        this.inputFinalSubmitLabel = finalSubmitItem.itemdisplaylabel;
      }
    },

    async loadAgreementDetails() {
      try {

        let responseData = {};

        // Only fetch data if we don't have preloaded data
        if (this.activeSuperdoc) responseData = this.activeSuperdoc.agreement.responseData;
        else responseData = await this.getAgreementDetails();

        // blank documents will not have initial data
        this.templateBase64 = responseData.initialdata || ' ';

        if (!responseData.newagreementid) {
          this.failedToLoadAgreementDetails();
        } else {
          this.initAgreementData(responseData);
          this.agreementData = responseData;
          if (this.ckeditorFileId) this.setOriginalFileType('word');
          else if (this.activeSuperdoc) {
            this.setOriginalFileType('superdoc-word');
            const agreementTitle = this.activeSuperdoc.agreement?.title || this.activeSuperdoc.name;
            this.updateAgreementTitle(agreementTitle);
          }
          else this.setOriginalFileType('pdf');
          this.$emit('agreement-data-change', this.agreementData);
        }

        if (!this.activeSuperdoc) {
          // In case of a draft, we use the CK Editor document title since the agreement has no title yet.
          this.harbourStore.updateHTMLPageTitle(this.documentTitle || this.agreementTitle);
        };
      } catch (error) {
        this.failedToLoadAgreementDetails();
        console.error(error);
      } finally {
        this.isLoadingActiveTemplate = false;
      }
    },

    getFinalizedSignersList() {
      // Get default signers
      const defaultSignersJsonArray = [];
      this.signersListInfo.forEach((signer) => {
        if (signer.isactive === true) {
          defaultSignersJsonArray.push({
            signertype: 'INDIVIDUAL',
            signerindex: signer.signerindex,
            signerid: signer.signerid,
            signeremail: signer.signeremail,
            signername: signer.signername,
            signeriseditingdisabled: false,
            signerisremovaldiasbled: false,
          });
        }
      });
      return defaultSignersJsonArray;
    },

    // called before agreement store/update (indices used when appending inputs on backend)
    addSectionIndexToAllInputs() {
      this.signersStore.allSignerInputs.forEach((input) => {
        const currentInput = input;
        const sectionIndex = this.selfServiceSections.findIndex(
          (section) => section.id === currentInput.sectionid,
        );
        currentInput.sectionindex = sectionIndex;
      });
    },

    async changeAgreementPublishSettingsRequest(superdocTemplate = false) {
      if (!this.is_template && this.creationMode !== 'template') return;

      let isTemplate = (this.is_template ? true : false) || !!this.selectedTemplateGroupId;

      if (this.activeSuperdoc?.id && superdocTemplate === false) isTemplate = false;
      const res = await this.$harbourData.post('data?agreement_editor_publish_agreement', {
        requesttype: 'agreement_editor_publish_agreement',
        templateid: this.activeSuperdoc ? this.activeSuperdoc.agreement.id : this.activeTemplateId,
        is_template: isTemplate,
        template_group_id: this.selectedTemplateGroupId,
        superdoc_draft_template: this.activeSuperdoc?.id && this.is_template ? true : false,
      });
      return res.data;
    },

    async changeAgreementPublishSettings(superdocTemplate = false) {
      if (this.activeTemplateId || superdocTemplate) {
        try {
          await this.changeAgreementPublishSettingsRequest(superdocTemplate);
        } catch (error) {
          console.log(error);
          this.$buefy.toast.open({
            message:
              'Changing template publish settings failed, Try again later or contact support@harbourshare.com',
            type: 'is-danger',
            position: 'is-top',
            duration: 3500,
          });
        }
      }
    },

    getFinalizedPdfPages() {
      // Validate: at least one visible PDF page available?
      let eligiblePages = JSON.parse(JSON.stringify(this.pdfPages));
      eligiblePages = eligiblePages.filter((p) => p.pageisactive === true);
      if (eligiblePages.length === 0) return null;

      // Get pages without included base64 image previews (*no need to send to server)
      const pdfPagesNoPreviewImgs = [];
      eligiblePages.forEach((page) => {
        const pageCopy = JSON.parse(JSON.stringify(page));
        pageCopy.pagepngbase64 = null;
        pdfPagesNoPreviewImgs.push(pageCopy);
      });
      return pdfPagesNoPreviewImgs;
    },

    async initBranding() {
      let branding;
      if (!this.agreementId) {
        branding = await this.brandingsStore.getOrganizationDefaultBranding();
      } else {
        branding = await this.brandingsStore.getAgreementBranding(this.agreementId);
      }
      this.activeBrandingId = branding.id;
      this.updateBrandingBannerUrl(branding.banner_url);
      this.updateBrandingLogoUrl(branding.logo_url);
    },

    updateAgreementPermissionNotification() {
      this.$buefy.dialog.alert({
        title: 'Template editing permissions',
        message: `<strong> You don't have permissions to edit this agreement.</strong>
                                <BR>
                                contact the template owner or <a href="mailto:support@harbourshare.com" target="_blank">support@harbourshare.com</a>
                                to help you with this request.
                                <BR><BR>`,
        confirmText: 'Create new agreement',
        type: 'is-warning',
        hasIcon: true,
        icon: 'exclamation-circle',
        iconPack: 'fas',
        ariaRole: 'alertdialog',
        ariaModal: true,
        onConfirm: () => this.startNewDocument(),
      });
    },

    async storeAgreementRequest({ defaultSignersJsonArray, pdfPages }) {
      const ckFileCheck = this.originalFileType === 'word' ? this.activeFileVersionDisplayId : null;
      if (ckFileCheck) this.prepareCkDataOnStoreUpdateAgreement();

      try {
        const res = await this.$harbourData.post('data?agreementeditor-storeagreement', {
          requesttype: 'agreementeditor-storeagreement',
          pdffiles: this.pdfFiles,
          pdfpages: pdfPages,
          pdfannotations: this.pdfAnnotations,
          pdfeditoritems: [],
          pdfpagescalefactor: this.pdfPageScaleFactor,
          signerinputs: this.signersStore.allSignerInputsList,
          agreementsubheader: this.agreementSubheader,
          signersdefaultjsonsarray: defaultSignersJsonArray,
          documentinputs: this.signersStore.documentInputs,
          agreementtitle: this.agreementTitle,
          activetemplateid: this.activeTemplateId,
          isorderenforced: this.isSignerOrderEnforced,
          fileversiondisplayid: this.topParentFileVersionDisplayId,
          sectionsarray: this.selfServiceSections,
          ishiddeninputpdfpreview: this.isHiddenInputPdfPreview,
          ishiddeninputacceptedandagreed: this.isHiddenInputAcceptedAndAgreed,
          ishiddeninputfinalesignature: this.isHiddenInputFinalEsignature,
          ishiddeninputfinaldisplay: this.isHiddenInputFinalDisplay,
          inputacceptedandagreedlabel: this.inputAcceptedAndAgreedLabel,
          inputfinalsubmitlabel: this.inputFinalSubmitLabel,
          ckeditorhtmldata: this.currentHtml,
          ckeditoragreementid: ckFileCheck,
          ckeditoranchors: this.ckeditorAnchors,
          superdoc_id: this.activeSuperdoc?.id,
        });

        return res.data;
      } catch (error) {
        this.$emit('error-storing-ck-agreement', {
          error,
          closeModal: false,
        });
        return null;
      }
    },

    prepareCkDataOnStoreUpdateAgreement() {
      const ckFileCheck = this.originalFileType === 'word' ? this.activeFileVersionDisplayId : null;
      if (ckFileCheck) {
        const ckInstance = this.$_ckeditorInstance;
        const ckHtml = ckInstance?.getData() || '';
        if (this.isCkeditorAgreement && this.creationMode === 'template') {
          // get updated html from ckeditor when updating template
          this.currentHtml = ckHtml || this.currentHtml || '';
        } else if (!this.currentHtml) {
          this.currentHtml = ckHtml;
        }
      }
    },

    shouldDeleteDraft() {
      return (
        !this.activeSuperdoc?.id &&
        this.draftId &&
        this.creationMode === 'template'
      );
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    /**
     * @ckeditor method
     */
    async storeCkeditorData(hideSaveMessage = false) {
      if (!hideSaveMessage) this.isCurrentlyStoringAgreement = true;

      // store ckeditor agreement data as file version (for agreement link ckeditor clone)
      console.log('Storing new ckeditor file version...');
      let filename = this.agreementTitle;
      if (!filename) {
        filename = 'document.docx';
      } else {
        filename = filename.replace('.docx', '');
        filename = `${this.agreementTitle}.docx`;
        const regexPattern = /[^0-9a-zA-Z_]+/g;
        filename = filename.replace(regexPattern, '');
        filename += '.docx';
      }
      let fileData;
      let fileSize;
      if (this.uploadedFile !== null) {
        fileData = this.uploadedFile.fileBase64;
        fileSize = this.uploadedFile.fileSizeBytes;
      } else if (this.$_ckeditorInstance) {
        fileData = await this.toBase64(this.$_ckeditorInstance.getData());
        fileSize = new Blob([fileData]).size;
      } else {
        return;
      }
      if (fileData) {
        const fileVersionDisplayId = this.agreementEditorStore.generateFileVersionDisplayId();
        const metaData = {
          fileVersionDisplayId,
          fileName: filename,
          fileBase64: fileData,
          fileSizeBytes: fileSize,
          isOriginalFile: false,
          ancestorDisplayId: this.activeFileVersionDisplayId,
          inputMethod: 'UPLOAD',
          documentsharing: this.documentSharing,
        };
        await this.agreementEditorStore.storeUpdateFileVersion(metaData);
      }

      // store ckeditor pdf (generated from ckeditor html data)
      console.log('Storing ckeditor PDF data...');

      const html = this.$_ckeditorInstance.getData() || '<html></html>';
      let pdfDataBlob = null;
      try {
        pdfDataBlob = await this.ckeditorStore.convertCkeditorDataToPdf(
          this.activeFileVersionDisplayId,
          html,
          this.activeLinkDisplayId
        );
      } catch (e) {
        const { data } = e.response;
        // convert json blob to json object
        const json = JSON.parse(await data.text());
        console.log('Error converting ckeditor data to pdf', json.message);
        return;
      }

      const fileBase64 = await readFileAsDataUrl(pdfDataBlob);
      const fileId = `pdf-${filename.replace(/\W/g, '')}-${Math.random()
        .toString(36)
        .slice(2)}-${Date.now()}`;
      const newFile = {
        pdfagreementname: filename.split('.')[0],
        pdfbase64: fileBase64,
        pdfbytes: pdfDataBlob.size,
        pdffilename: filename,
        pdfid: fileId,
        pdfinputmethod: 'UPLOAD',
      };

      this.updatePdfFiles([newFile]);
      const pages = await this.ckeditorStore.getCkeditorPdfPages(fileBase64, fileId);
      this.updatePdfPages(pages);

      if (!hideSaveMessage) this.isCurrentlyStoringAgreement = false;
    },

    async storeAgreement() {
      const { defaultSignersJsonArray, pdfPages, isTemplatesPage } = this.prepareStoringData();
      this.updatePublishStepDelayed();

      try {
        const responseData = await this.storeAgreementRequest({
          defaultSignersJsonArray,
          pdfPages,
        });

        if (!responseData) {
          throw new Error('Missing stored agreement data');
        }

        const storedNewAgreementId = responseData.newagreementid;
        this.activeTemplateId = storedNewAgreementId;
        this.publishStep = 2;
        await this.storeAgreementBranding();
        //update latest branding for future agreelinks
        this.harbourStore.getPersonalizationContent()
        // update branding gallery data
        this.brandingsStore.getAllBrandings({force: true});
        await this.changeAgreementPublishSettings();

        this.updateTemplates(isTemplatesPage);

        this.templatesStore.getRecentUsedTemplates();
        this.publishStep = 3;
        this.isEditingAgreementMode = true;
        this.isCurrentlyStoringAgreement = false;

        // If this is for CK and this is a template, we have finished AEv2 and
        // Must remove the draft
        if (this.shouldDeleteDraft()) this.draftsStore.deleteDraft(this.draftId);

        this.openNextStepModal({ agreementId: storedNewAgreementId });
      } catch (error) {
        this.handleAgreementStoreError({ error });
      } finally {
        this.isCurrentlyStoringAgreement = false;
      }
    },

    handleAgreementStoreError({ error, isUpdate = false} = {}) {
      if (!error) return;

      console.warn(error);
      try {
        Sentry.captureException(error);
      } catch (e) {
        console.error(e);
      }
      this.isCurrentlyStoringAgreement = false;

      const storeOrUpdate = isUpdate ? 'update' : 'store';
      const message = `Unable to ${storeOrUpdate} new agreement at this time -- try again in a bit or contact support@harbourshare.com`;
      this.$buefy.toast.open({
        message,
        type: 'is-danger',
        position: 'is-top',
        duration: 3500,
      });
    },

    async storeAgreementBrandingRequest() {
      const res = await this.$harbourData.post('data?agreement_branding_add_branding', {
        requesttype: 'agreement_branding_add_branding',
        agreementid: this.activeTemplateId,
        agreementtitle: this.agreementTitle,
        brandingbannerbase64: await this.getBrandingBannerBase64(),
        brandinglogobase64: await this.getBrandingLogoBase64(),
      });

      return res.data;
    },

    async storeAgreementBranding() {
      try {
        const responseData = await this.storeAgreementBrandingRequest();
        this.activeBrandingId = responseData?.agreementbrandinguid;
        this.brandingBannerImgUrl = responseData?.branding_banner_url;
        this.brandingLogoImgUrl = responseData?.branding_logo_url;

      } catch (error) {
        console.log(error);
        try {
          Sentry.captureException(error);
        } catch (e) {
          console.error(e);
        }
        this.$buefy.toast.open({
          message:
            'Unable to store new agreement at this time -- try again in a bit or contact support@harbourshare.com',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
      }
    },

    // ToDo check if still required
    processInputsBeforeUpdate(inputs) {
      const processedInputs = [...inputs];
      for (const input of processedInputs) {
        if (input.itemfieldtype !== 'IMAGEINPUT') continue;
        const inputAnnotations = this.pdfAnnotations.filter(annotation => annotation.inputitemid === input.id);
        input.itemtypeconfig = {
          ...input.itemtypeconfig,
          imagescount: inputAnnotations.length,
        }
      }
      return processedInputs;
    },

    async updateAgreementRequest({
      defaultSignersJsonArray,
      pdfPages,
      updateSummaryText,
      agreementId = null,
      signal = null,
      isSuperdocFlowComplete = false,
    }) {
      const ckFileCheck = this.originalFileType === 'word' ? this.activeFileVersionDisplayId : null;
      this.prepareCkDataOnStoreUpdateAgreement();

      const agreementIdToUpdate = agreementId || this.activeTemplateId;

      const payload = {
        requesttype: 'agreementeditor-updateagreement',
        pdffiles: this.pdfFiles,
        agreementid: agreementIdToUpdate,
        pdfpages: pdfPages,
        pdfannotations: this.pdfAnnotations,
        pdfeditoritems: [],
        pdfpagescalefactor: this.pdfPageScaleFactor,
        signerinputs: this.signersStore.allSignerInputsList,
        signersdefaultjsonsarray: defaultSignersJsonArray,
        documentinputs: this.signersStore.documentInputs,
        agreementtitle: this.agreementTitle,
        agreementsubheader: this.agreementSubheader,
        activetemplateid: agreementIdToUpdate,
        isorderenforced: this.isSignerOrderEnforced,
        fileversiondisplayid: this.topParentFileVersionDisplayId,
        linkdisplayid: this.activeLinkDisplayId,
        sectionsarray: this.selfServiceSections,
        updatesummary: updateSummaryText,
        brandinguid: this.activeBrandingId,
        brandingbannerbase64: await this.getBrandingBannerBase64(),
        brandinglogobase64: await this.getBrandingLogoBase64(),
        ishiddeninputpdfpreview: this.isHiddenInputPdfPreview,
        ishiddeninputacceptedandagreed: this.isHiddenInputAcceptedAndAgreed,
        ishiddeninputfinalesignature: this.isHiddenInputFinalEsignature,
        ishiddeninputfinaldisplay: this.isHiddenInputFinalDisplay,
        inputacceptedandagreedlabel: this.inputAcceptedAndAgreedLabel,
        inputfinalsubmitlabel: this.inputFinalSubmitLabel,
        ckeditorhtmldata: this.currentHtml || '',
        ckeditoragreementid: ckFileCheck,
        ckeditoranchors: this.ckeditorAnchors,
        isdraft: Boolean(this.draftId),
        draftid: this.draftId,
        superdoc_id: this.activeSuperdoc?.id || this.superdocProp?.id,
        isSuperdocFlowComplete,
      };

      const url = '/data?agreementeditor-updateagreement';
      const res = await this.$harbourData.post(url, payload, null, { signal });
      return res.data;
    },

    async finalUpdateSuperdocAgreement() {
      this.isCurrentlyStoringAgreement = true;
      const { pdfPages, updateSummaryText, isTemplatesPage } = this.prepareStoringData();
      const params = {
        defaultSignersJsonArray: this.activeSuperdoc.agreement.signers,
        pdfPages,
        updateSummaryText,
        agreementId: this.activeSuperdoc.agreement.id,
        isSuperdocFlowComplete: true,
      };

      const promises = [
        this.$_superdoc?.save(),
      ];

      if (this.is_template) {
        promises.push(this.changeAgreementPublishSettings(true));
        this.publishStep = 1;
      }
      promises.push(this.updateAgreementRequest(params));
      await Promise.all(promises);
      this.isCurrentlyStoringAgreement = false;
      this.updateTemplates(isTemplatesPage);
      this.processSuperdocAgreementUpdated();
    },

    processSuperdocAgreementUpdated() {
      this.openNextStepModal({
        agreementId: this.activeSuperdoc.agreement.id,
      });
    },

    async processSuperdocAgreementCreated(promise) {
      this.isCurrentlyStoringAgreement = true;
      this.updatePublishStepDelayed();

      // Await for the promise to resolve
      try {
        const result = await promise;
        this.openNextStepModal({ agreementId: result.newagreementid });
      } catch (err) {
        this.handleAgreementStoreError({ error: err });
      }
    },

    prepareStoringData({ isUpdate = false } = {}) {
      this.destroyAgreementUpdatePolling(true);
      const pdfPages = this.getFinalizedPdfPages();
      const defaultSignersJsonArray = this.getFinalizedSignersList();
      const isTemplatesPage = this.shouldOpenTemplatesPage();
      const updateSummaryText = isUpdate ? this.summaryArray.join(' and ') : null;

      // apply section index to each input
      this.addSectionIndexToAllInputs();

      return { defaultSignersJsonArray, pdfPages, updateSummaryText, isTemplatesPage };
    },

    updatePublishStepDelayed() {
      this.isCurrentlyStoringAgreement = true;
      this.publishStep = 0;
      setTimeout(() => {
        if (this.publishStep === 0) {
          this.publishStep = 1;
        }
      }, 1900);
    },

    async updateTemplates(isTemplatesPage) {
      // Only update templates if this is a template
      if (this.creationMode !== 'template') return;

      if (isTemplatesPage) {
        await Promise.all([
          this.templatesStore.getTemplateGroups(),
          this.templatesStore.getTemplates(),
        ])
      } else {
        this.templatesStore.getTemplateGroups();
        this.templatesStore.getTemplates();
      }
    },

    async updateAgreementStart() {
      const {
        defaultSignersJsonArray,
        pdfPages,
        updateSummaryText,
        isTemplatesPage
      } = this.prepareStoringData({ isUpdate: true });

      this.updatePublishStepDelayed();

      try {
        const responseData = await this.updateAgreementRequest({
          defaultSignersJsonArray,
          pdfPages,
          updateSummaryText,
        });

        if (!responseData?.newagreementid) {
          // newagreementid is null if original agreement is not found
          this.updateAgreementPermissionNotification();
        } else {
          const storedNewAgreementId = responseData.newagreementid;
          this.activeTemplateId = storedNewAgreementId;
          this.publishStep = 2;
          await this.changeAgreementPublishSettings();

          // Update templates page if this is a template
          this.updateTemplates(isTemplatesPage);

          this.templatesStore.getRecentUsedTemplates();
          this.publishStep = 3;
          this.isCurrentlyStoringAgreement = false;

          // If this is for CK and this is a template, we have finished AEv2 and
          // Must remove the draft
          if (this.shouldDeleteDraft()) this.draftsStore.deleteDraft(this.draftId);

          this.harbourStore.resetHTMLPageTitle();
          this.openNextStepModal({ agreementId: storedNewAgreementId, forceCkeditorHtml: true });
        }
      } catch (error) {
        this.handleAgreementStoreError({ error, isUpdate: true });
      } finally {
        this.isCurrentlyStoringAgreement = false;
      }
    },

    updateAgreement() {
      this.updateAgreementStart();
    },

    shouldOpenTemplatesPage() {
      const isAgreementMode = this.creationMode === 'agreement';
      const isTemplateMode = this.creationMode === 'template';
      if (isAgreementMode || (this.isCkeditorAgreement && !isTemplateMode)) {
        return false;
      }
      return true;
    },

    openNextStepModal({ agreementId, forceCkeditorHtml }) {
      const isTemplatesPage = this.shouldOpenTemplatesPage();
      if (!isTemplatesPage) {

        this.openAgreementLinkModalWrapper({
          agreementId,
          draftId: this.draftId,
          forceCkeditorHtml,
        });
      } else {
        this.showInTemplatesPage({ agreementId });
      }
    },

    showInTemplatesPage() {
      this.templatesStore.setActiveTemplateGroup(this.selectedTemplateGroupId);
      this.closeModal({ source: 'template-created' });
      this.harbourStore.$router?.push({ name: 'templates' });
    },

    openAgreementLinkModalWrapper({ agreementId, draftId, forceCkeditorHtml = false }) {
      const ckeditorFileId = this.isCkeditorAgreement ? this.activeFileVersionDisplayId : null;
      const ckeditorHtmlData = this.isCkeditorAgreement ? this.$_ckeditorInstance.getData() : null;
      const ckeditorAnchors = this.ckeditorAnchors;

      const props = {
        agreementId,
        agreementCreationMode: this.creationMode,
        linkDisplayIdToEdit: this.activeLinkDisplayId,
        isAgreementSignOrderCheckboxHidden: true,
        isAgreementEditorFlow: true,
        ckeditorFileId,
        ckeditorHtmlData,
        ckeditorAnchors,
        draftId,
        lastbrand: false,
        forceCkeditorHtml,
        isEditingApprovalFlowLink: this.isEditingApprovalFlowLink,
        approvalEditData: this.approvalEditData,
        isCompletedApproval: this.isCompletedApproval,
        superdocId: this.activeSuperdoc?.id,
        superdocProp: this.activeSuperdoc,
      };

      setTimeout(() => {
        this.$openAgreementLinkModal({ props });
      }, 250);

      this.$emit('OPEN-LINK');
      this.closeModal({ source: 'link-created' });
    },



    // The options will be passed from a HrbrCkeditorAfterUploadModal event.
    closeModal(options = {}) {
      if (options.html) {
        this.currentHtml = options.html;
      }

      if (this.activeSuperdoc) this.activeSuperdoc = null;

      const { source } = options;
      if (source === 'link-created' || source === 'template-created') {
        this.$emit('close-modal');
      } else if (source !== 'signature' && (this.firstCkOpen || this.skipToCk)) {
        this.$emit('close-modal');
      }

      if (source !== 'signature') {
        this.harbourStore.resetHTMLPageTitle();
      }

      this.firstCkOpen = false;
    },

    /**
     * @ckeditor method
     * Triggered by HrbrCkeditorAfterUploadModal "Prepare for signature" click event.
     */
    onCkeditorAfterUploadPrepareForSignature() {
      // go to add signers if no signers present
      if (!this.signersList.length) {
        this.updateCurrentStep(1);
      }
      // Set flag for Add Fields first load.
      // This is to let Add Fields init signers list.
      this.isAddFieldsStepFirstLoad = true;
    },

    /**
     * @ckeditor method
     * Triggered by HrbrCkeditorAfterUploadModal "Edit document" click event.
     */
    onCkeditorAfterUploadEditDocument() {
      // Set flag for Add Fields first load.
      // This is to let Add Fields init signers list.
      this.isAddFieldsStepFirstLoad = true;
    },

    /**
     * @ckeditor method
     */
    handleSkipToCk() {
      this.skipToCk = false;
    },

    /**
     * @ckeditor method
     */
    handleDocumentSharingUpdate(data) {
      this.documentSharing = data;
    },

    handleUpdateBase64(data) {
      this.originalBase64 = data;
    },

    /**
     * @ckeditor method
     */
    onAddAnchor(newAnchor) {
      this.ckeditorAnchors.push(newAnchor);
    },

    async saveUserSelectedOptions() {
      const settingstoupdate = {
        inputsettings: this.agreementEditorStore.inputSettingLastChanged,
      };

      if (this.isCkeditorAgreement || !this.activeTemplateId) {
        settingstoupdate.converttopdf = this.harbourStore.isAgreementEditorConvertToPdf
      }

      this.$harbourData.post('/data?account-updatesavedsettings', {
        requesttype: 'account-updatesavedsettings',
        settingstoupdate: settingstoupdate,
      });
    },

    updateIsConvertToPdf(isConvertToPdf) {
      this.harbourStore.isAgreementEditorConvertToPdf = isConvertToPdf;
    },

    openPricingTableModal() {
      this.$emit('open-pricing-table-modal');
    },

    onDocumentError(error) {
      const hasPassword = error.name === 'PasswordException';
      if (hasPassword) {
        this.$buefy.toast.open({
          message: 'Provided PDF cannot be processed as it is password-protected. Please re-export without password to continue.',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
      } else {
        this.$buefy.toast.open({
          message: 'Something went wrong while loading the document',
          type: 'is-danger',
          position: 'is-top',
          duration: 3500,
        });
      }
      this.isFileRemove = true;
    },

    initAgreementUpdatePolling(agreementId = null) {
      this.changeAgreeUpdatePollingAvailable(true);
      const polling = async () => {
        await this.saveAgreementChanges(agreementId);
        if (!this.isAgreeUpdatePollingDestroyed) {
          this.agreeUpdateTimeoutId = setTimeout(polling, this.agreeUpdateTimeout);
        }
      };
      this.agreeUpdateTimeoutId = setTimeout(polling, this.agreeUpdateTimeout);
    },

    changeAgreeUpdatePollingAvailable(value) {
      this.$emit('change-agree-update-polling-available', value);
    },

    destroyAgreementUpdatePolling(abortController = false) {
      clearTimeout(this.agreeUpdateTimeoutId);
      this.isAgreeUpdatePollingDestroyed = true;
      if (abortController) this.agreeUpdatePollingController?.abort();
    },

    async saveSuperdocAgreement() {
      if (this.isDestroyed || !this.activeSuperdoc?.agreement?.id) return;
      this.addSectionIndexToAllInputs();
      await this.updateAgreementRequest({
        defaultSignersJsonArray: this.getFinalizedSignersList(),
        pdfPages: [],
        updateSummaryText: this.summaryArray.join(' and '),
        agreementId: this.activeSuperdoc.agreement.id,
      });
    },

    async saveAgreementChanges(agreementId = null) {
      if (!this.isPdfPagesLoaded) return;

      try {
        this.$emit('save-agreement-changes');
        this.isSavingAgreementChanges = true;

        const pdfPages = this.getFinalizedPdfPages();
        const defaultSignersJsonArray = this.getFinalizedSignersList();
        const updateSummaryText = this.summaryArray.join(' and ');

        // apply section index to each input
        this.addSectionIndexToAllInputs();

        this.agreeUpdatePollingController?.abort(); // abort previous call
        this.agreeUpdatePollingController = new AbortController();
        const { signal } = this.agreeUpdatePollingController;

        await this.updateAgreementRequest({
          defaultSignersJsonArray,
          pdfPages,
          updateSummaryText,
          agreementId,
          signal,
        });

      } catch (error) {
        console.log(error);
      } finally {
        this.isSavingAgreementChanges = false;
      }
    },

    async imageUrlToBase64(url) {
      const imageBlob = await getBlobFromUrl(url)
      return readFileAsDataUrl(imageBlob)
    },

    async getBrandingBannerBase64(){
      //this.brandingBannerImgUrl may contain url to GCS or base64 data
      if (this.brandingBannerImgUrl) {
        if (this.brandingBannerImgUrl.startsWith("https://")
          || this.brandingBannerImgUrl.startsWith("static")) {
          return await this.imageUrlToBase64(this.brandingBannerImgUrl)
        }
        return this.brandingBannerImgUrl
      }
      return null
    },

    async getBrandingLogoBase64() {
      //this.brandingLogoImgUrl may contain url to GCS or base64 data
      if (this.brandingLogoImgUrl) {
        if (this.brandingLogoImgUrl.startsWith("https://")
          || this.brandingLogoImgUrl.startsWith("static")) {
          return await this.imageUrlToBase64(this.brandingLogoImgUrl)
        }
        return this.brandingLogoImgUrl
      }
      return null
    },

    getDraftChanges() {
      if (this.draftTemplateRemovedInputs.length) {
        this.signersStore.documentInputs = this.signersStore.documentInputs.filter((input) => !this.draftTemplateRemovedInputs.includes(input.id));
        range(1, this.signersStore.signersMaxCount).forEach((_, i) => {
          this[`signerInputs${i + 1}`] = this[`signerInputs${i + 1}`].filter((input) => !this.draftTemplateRemovedInputs.includes(input.id));
        });
      }

      if (Object.keys(this.draftTemplateChangedData).length) {
        for (const [key, value] of Object.entries(this.draftTemplateChangedData)) {
          const input = this.signersStore.documentInputs.find(input => input.id === key);
          if (input) input.itemdefaultvalue = value;
        }
      }

      this.draftTemplateRemovedInputs = [];
      this.draftTemplateChangedData = {};
    },

    updateCurrentSuperdocReference({ superdoc }) {
      this.$_superdoc = superdoc;
    }
  },

  async created() {
    this.currentCreationMode = this.creationMode;
    this.setInitialData();

    if (!this.ckeditorFileId && this.isEditingAgreementMode) {
      this.$emit('is-waiting-for-pdf-pages', true);
    }

    // check for isEditingAgreementMode to prevent signer data overwrite when editing agreement
    this.isReusableLinkEnabled = (
      this.isAgreementCreationMode &&
      this.harbourStore.isAgreementEditorReusableLinkEnabled &&
      !this.isEditingAgreementMode
    );

    this.isReadyToRender = true;
    this.selectedTemplateGroupId = this.templateGroupId;
    this.attachStepHandlers();

    if (this.isSkipToCk) this.skipToCk = true;
    if (this.source === 'shared-agreement') this.firstCkOpen = false;

    // preload the org and agreements branding while the user is building their agreement for speed
    this.brandingsStore.getAllBrandings();
    if (this.agreementId) await this.loadAgreementDetails();
    await this.initBranding();

    // start agreement update polling after loading agreement data
    const shouldStartAgreeUpdatePolling = (
      this.agreementId &&
      this.sourceDraftId &&
      this.isPdfAgreement
    );

    if (shouldStartAgreeUpdatePolling) this.initAgreementUpdatePolling();

    this.getDraftChanges();
  },

  async beforeDestroy() {
    this.isAgreeUpdatePollingDestroyed = true;
    if (this.superdocId || this.activeSuperdoc) {
      if (this.activeSuperdoc?.agreement?.id) await this.saveSuperdocAgreement();
      this.isDestroyed = true;
      this.$nextTick(() => (this.activeSuperdoc = null));
    }
    this.signersStore.$reset();
    this.ckeditorStore.resetInputFontSizeMap();
    this.destroyAgreementUpdatePolling();
  },
};
</script>

<template>
  <div
    :class="['hrbr-agreement-editor', 'hrbr-agreement-editor--' + currentStepId]"
    id="hrbr-agreement-editor">
    <div class="hrbr-agreement-editor__screens">
      <div
        class="hrbr-agreement-editor__screen"
        v-show="currentStepId === 'upload-document' && isReadyToRender">
        <HrbrAgreementEditorUploader
          v-bind="{
            isFileRemove,
            agreementData,
            activeFileVersionDisplayId,
            topParentFileVersionDisplayId,
            activeTemplateId,
            isUploaderStepFirstLoad,
            creationMode,
            addonKey,
            ckeditorFileId,
            isSkipToCk,
            templateBase64,
            isConvertToPdfInit: harbourStore.isAgreementEditorConvertToPdf,
            isBlankCkDocument,
            fileToUpload,
            isCkDocumentFromFile,
            isPdfDocumentFromFile,
            templateGroupId: selectedTemplateGroupId,
          }"
          @open-pricing-table-modal="openPricingTableModal"
          @upload-file="onFileUpload"
          @initial-ckeditor-upload="onInitialCkeditorUpload"
          @sync-complete="onSyncComplete"
          @remove-file="onFileRemove"
          @error="onFileError"
          @update-current-step="updateCurrentStep"
          @file-version-display-id-change="updateFileVersionDisplayId"
          @top-parent-file-version-display-id-change="updateTopParentFileVersionDisplayId"
          @update-creation-mode="updateCreationMode"
          @update-documentsharing="handleDocumentSharingUpdate"
          @update-original-base-64="handleUpdateBase64"
          @convert-to-pdf-change="updateIsConvertToPdf"
          @modal-close="closeModal">
        </HrbrAgreementEditorUploader>
      </div>

      <div class="hrbr-agreement-editor__screen" v-if="currentStepId === 'add-signers'">
        <HrbrAgreementEditorAddSigners
          v-bind="{
            contextDict,
            creationMode,
            agreementData,
            linkSigners,
            pdfPageScaleFactor,
            pdfFiles,
            pdfPages,
            signersList,
            isReusableLinkEnabled,
            isSignerOrderEnforced,
            templateGroupId: selectedTemplateGroupId,
            summaryArray,
            originalFileType,
            activeFileVersionDisplayId,
            isAddSignersStepFirstLoad,
            ckeditorFileId,
            isCopy,
            agreementTitle,
            isEditingApprovalFlowLink,
            approvalEditData,
            isCompletedApproval,
            uploadedFile,
            isSuperdocWordAgreement,
          }"
          @on-document-error="onDocumentError"
          @update-current-step="updateCurrentStep"
          @pdf-pages-change="updatePdfPages"
          @signers-list-change="updateSignersList"
          @template-signers-loaded="onTemplateSignersLoad"
          @reusable-link-toggle-change="updateReusableLinkToggle"
          @signer-order-enforced-change="updateSignerOrderEnforced"
          @template-group-id-change="updateTemplateGroupId"
          @summary-array-change="updateSummaryArray"
          @update-agreement-title="updateAgreementTitle"
          @pages-loaded="onPdfPagesLoaded">
        </HrbrAgreementEditorAddSigners>
      </div>

      <div
        class="hrbr-agreement-editor__screen"
        v-if="currentStepId === 'add-fields' && activeFileVersionDisplayId">
        <HrbrAgreementEditorAddFields
          v-bind="{
            contextDict,
            creationMode,
            agreementData,
            uploadedFile,
            linkSigners,
            publishLock,
            signersList,
            signersListInfo,
            isReusableLinkEnabled,
            pdfPageScaleFactor,
            pdfFiles,
            pdfPages,
            pdfAnnotations,
            summaryArray,
            currentSectionId,
            isPdfEditingEnabled,
            activeTemplateId,
            agreementSubheader,
            brandingLogoImgUrl,
            brandingBannerImgUrl,
            agreementTitle,
            templateGroupId: selectedTemplateGroupId,
            isActiveAgreement,
            syncComplete,
            activeFileVersionDisplayId,
            topParentFileVersionDisplayId,
            isAddFieldsStepFirstLoad,
            isEditingAgreementMode,
            isSignersSettingsChanged,
            originalFileType,
            ckeditorFileId,
            lastbrand,
            documentSharing,
            activeFileVersionDisplayId,
            templateBase64,
            linkItem,
            linkDisplayId,
            isInputSettingsOpened,
            isInitialCkeditorUpload,
            isBlankDocument,
            ckeditorAnchors,
            currentStep,
            isEditingApprovalFlowLink,
            isCompletedApproval,
            approvalEditData,
            draftId,
            templateGroupId,
            isSuperdocWordAgreement,
          }"
          @on-document-error="onDocumentError"
          @update-current-step="updateCurrentStep"
          @uploaded-file-change="updateUploadedFile"
          @signers-list-change="updateSignersList"
          @signers-list-info-change="updateSignersListInfo"
          @signer-inputs-change="updateSignerInputs"
          @document-inputs-change="updateDocumentInputs"
          @pdf-files-change="updatePdfFiles"
          @pdf-pages-change="updatePdfPages"
          @pdf-annotations-change="updatePdfAnnotations"
          @pdf-annotation-by-id-change="updateAnnotationById"
          @summary-array-change="updateSummaryArray"
          @update-current-section-id="updateCurrentSectionId"
          @update-agreement-subheader="updateAgreementSubheader"
          @update-agreement-title="updateAgreementTitle"
          @update-branding-logo-url="updateBrandingLogoUrl"
          @update-branding-banner-url="updateBrandingBannerUrl"
          @update-active-state="updateAgreementActiveState"
          @start-new-document="startNewDocument"
          @file-version-display-id-change="updateFileVersionDisplayId"
          @top-parent-file-version-display-id-change="updateTopParentFileVersionDisplayId"
          @update-active-templateid="updateActiveTemplateId"
          @update-default-input-visiablity="updateDefaultInputVisiablity"
          @update-default-input-label="updateDefaultInputLabel"
          @editor-ready="onCkeditorReady"
          @editor-destroy="onCkeditorDestroy"
          @editor-error="onCkeditorError"
          @modal-close="closeModal"
          @prepare-for-signature="onCkeditorAfterUploadPrepareForSignature"
          @edit-document="onCkeditorAfterUploadEditDocument"
          @template-group-id-change="updateTemplateGroupId"
          @skip-to-ck-complete="handleSkipToCk"
          @is-input-settings-opened-change="updateIsInputSettingsOpened"
          @initial-ckeditor-upload="onInitialCkeditorUpload"
          @add-anchor="onAddAnchor"
          @pages-loaded="onPdfPagesLoaded"
          @superdoc-ready="updateCurrentSuperdocReference">
        </HrbrAgreementEditorAddFields>
      </div>
      <HrbrAgreementEditorStoreProgress
        v-bind="{ publishStep, creationMode }"
        v-show="isCurrentlyStoringAgreement">
      </HrbrAgreementEditorStoreProgress>
    </div>
  </div>
</template>

<style lang="postcss" scoped></style>

<style>
#hrbr-agreement-editor.hrbr-agreement-editor,
#hrbr-agreement-editor .hrbr-agreement-editor__screens,
#hrbr-agreement-editor .hrbr-agreement-editor__screen {
  height: 100%;
}

.button.is-mediumgrey {
  background-color: #dfdfdf;
  border-color: #0000002e;
  color: #000000cf;
}
</style>
